// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { CmsBlock } from '../../route/Checkout/Checkout.component';

import './Testimonials.style.scss';

/** @namespace Seedsman/Component/Testimonials/Component */
export class TestimonialsComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        return (
            <div block="TestimonialsWrapper">
                 <CmsBlock identifier="Customer Feedback" />
                 <div block="TestimonialsWrapper" elem="Testimonials">
                     <CmsBlock identifier="testimonials" />
                 </div>

            </div>
        );
    }
}

export default TestimonialsComponent;
