import { CartIcon as SourceCartIcon } from 'SourceComponent/CartIcon/CartIcon.component';

import './CartIcon.style';

/** @namespace Seedsman/Component/CartIcon/Component */
export class CartIconComponent extends SourceCartIcon {
    renderMobCartIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="28.403" height="26.742" viewBox="0 0 28.403 26.742">
            <g id="Group_68559" data-name="Group 68559" transform="translate(1.004 4.463)">
                <g id="Group_59165" data-name="Group 59165" transform="translate(-0.254 1.559)">
                <path
                  id="Path_65"
                  data-name="Path 65"
                  d="M378.836,33.913a4.418,4.418,0,0,0-4.5-4.334h0a4.418,4.418,0,0,0-4.5,4.334"
                  transform="translate(-364.743 -29.579)"
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeWidth="1.5"
                />
                <rect
                  id="Rectangle_119"
                  data-name="Rectangle 119"
                  width="18.402"
                  height="14.021"
                  rx="1.088"
                  transform="translate(0 5.949)"
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeWidth="1.5"
                />
                </g>
            </g>
            </svg>
        );
    }
    /* eslint-disable max-len */

    renderSeedsManCartIcon() {
        return (
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0029 28C13.4529 28 12.9823 27.8043 12.5909 27.413C12.1989 27.021 12.0029 26.55 12.0029 26C12.0029 25.45 12.1989 24.979 12.5909 24.587C12.9823 24.1957 13.4529 24 14.0029 24C14.5529 24 15.0236 24.1957 15.4149 24.587C15.8069 24.979 16.0029 25.45 16.0029 26C16.0029 26.55 15.8069 27.021 15.4149 27.413C15.0236 27.8043 14.5529 28 14.0029 28ZM24.0029 28C23.4529 28 22.9823 27.8043 22.5909 27.413C22.1989 27.021 22.0029 26.55 22.0029 26C22.0029 25.45 22.1989 24.979 22.5909 24.587C22.9823 24.1957 23.4529 24 24.0029 24C24.5529 24 25.0239 24.1957 25.4159 24.587C25.8073 24.979 26.0029 25.45 26.0029 26C26.0029 26.55 25.8073 27.021 25.4159 27.413C25.0239 27.8043 24.5529 28 24.0029 28ZM13.1529 12L15.5529 17H22.5529L25.3029 12H13.1529ZM14.0029 23C13.2529 23 12.6863 22.6707 12.3029 22.012C11.9196 21.354 11.9029 20.7 12.2529 20.05L13.6029 17.6L10.0029 10H8.97793C8.6946 10 8.46126 9.904 8.27793 9.712C8.0946 9.52067 8.00293 9.28333 8.00293 9C8.00293 8.71667 8.09893 8.479 8.29093 8.287C8.48226 8.09567 8.7196 8 9.00293 8H10.6279C10.8113 8 10.9863 8.05 11.1529 8.15C11.3196 8.25 11.4446 8.39167 11.5279 8.575L12.2029 10H26.9529C27.4029 10 27.7113 10.1667 27.8779 10.5C28.0446 10.8333 28.0363 11.1833 27.8529 11.55L24.3029 17.95C24.1196 18.2833 23.8779 18.5417 23.5779 18.725C23.2779 18.9083 22.9363 19 22.5529 19H15.1029L14.0029 21H25.0279C25.3113 21 25.5446 21.0957 25.7279 21.287C25.9113 21.479 26.0029 21.7167 26.0029 22C26.0029 22.2833 25.9069 22.5207 25.7149 22.712C25.5236 22.904 25.2863 23 25.0029 23H14.0029Z" fill="black" />
            </svg>

        );
    }

    render() {
        const { isActive, isMobile, seedsMan } = this.props;

        if (isMobile) {
            return this.renderMobCartIcon();
        }

        if (seedsMan && !isMobile) {
            return this.renderSeedsManCartIcon();
        }

        return (
            <svg xmlns="http://www.w3.org/2000/svg" mods={ { isActive } } block="CartIcon" width="24.804" height="23.612" viewBox="0 0 24.804 23.612">
                <g id="Group_68559" data-name="Group 68559" transform="translate(0.75 4.358)">
                    <g id="Group_59165" data-name="Group 59165" transform="translate(0 3.196)">
                        <path
                          id="Path_65"
                          data-name="Path 65"
                          d="M376.737,32.9a3.387,3.387,0,0,0-3.447-3.323h0a3.387,3.387,0,0,0-3.447,3.323"
                          transform="translate(-365.934 -29.579)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeWidth="1.5"
                        />
                        <rect
                          id="Rectangle_119"
                          data-name="Rectangle 119"
                          width="14.107"
                          height="10.748"
                          rx="1.088"
                          transform="translate(0 4.56)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeWidth="1.5"
                        />
                    </g>
                </g>
            </svg>
        );
    }
}

export default CartIconComponent;
