import CmsBlockQuery from 'Query/CmsBlock.query';
import {
    CmsBlockContainer as SourceCmsBlockContainer
} from 'SourceComponent/CmsBlock/CmsBlock.container';
import { fetchQuery } from 'Util/Request';

/** @namespace Seedsman/Component/CmsBlock/Container */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    containerProps() {
        const { blockType, showLoader } = this.props;
        const { cmsBlock } = this.state;

        return { cmsBlock, blockType, showLoader };
    }

    async _getCmsBlock() {
        const { identifier } = this.props;
        if (!identifier) {
            return;
        }
        const { cmsBlocks: { items } } = await fetchQuery(
            [CmsBlockQuery.getQuery({ identifiers: [identifier] })]
        );

        if (!items.length) {
            return;
        }

        this.setState({ cmsBlock: items[0] });
    }
}

export default CmsBlockContainer;
