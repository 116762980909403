/* eslint-disable fp/no-let */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { importScript } from 'Util/Script';
import { testimonialsWidget } from 'Util/Widget';

import { REVIEWS_WIDGET_URL } from '../AddScriptLinks/AddScriptLinks.config';
import TestimonialsComponent from './Testimonials.component';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace Seedsman/Component/Testimonials/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
    )
});

/** @namespace Seedsman/Component/Testimonials/Container/mapStateToProps */
export const mapStateToProps = () => ({
});

/** @namespace Seedsman/Component/Testimonials/Container */
export class TestimonialsContainer extends PureComponent {
    static propTypes = {
        updateBreadcrumbs: PropTypes.func.isRequired
        // TODO: implement prop-types
    };

    state = {
        isTestimonialsLoaded: false
    };

    componentDidMount() {
        this.testimonialsScript();
        this.renderTestimonialsWidget();
        this._updateBreadcrumbs();
    }

    componentDidUpdate(__, prevState) {
        const { isTestimonialsLoaded: prevIsTestimonialsLoaded } = prevState;
        const { isTestimonialsLoaded } = this.state;

        if (prevIsTestimonialsLoaded !== isTestimonialsLoaded) {
            this.testimonialsScript();
            this._updateBreadcrumbs();
        }
    }

    containerProps() {
        return {
        };
    }

    _updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;
        const breadcrumbs = [
            { url: '/CustomerFeedback', name: __('CustomerFeedback') }
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    testimonialsScript() {
        const { reviewWidgetUrl = REVIEWS_WIDGET_URL } = this.props;
        const reviewScript = document.getElementById('testimonialWidget');

        if (reviewScript === null) {
            importScript(reviewWidgetUrl, 'testimonialWidget');
            this.setState({
                isTestimonialsLoaded: true
            });
        }
    }

    renderTestimonialsWidget() {
        const { isTestimonialsLoaded } = this.state;
        const reviewContainer = document?.getElementById('testimonials');
        const MAX_RETRIES = 5;
        let retries = 0;
        const TIMEOUT = 1000;

        const scriptLoading = setInterval(() => {
            if (!isTestimonialsLoaded || typeof carouselInlineWidget !== 'function') {
                if (retries < MAX_RETRIES) {
                    retries++;
                    this.renderTestimonialsWidget();
                } else {
                    clearInterval(scriptLoading);
                }
            } else {
                if (typeof carouselInlineWidget === 'function' && !reviewContainer) {
                    const script = document.createElement('script');
                    script.textContent = testimonialsWidget();

                    script.onload = () => {
                        // eslint-disable-next-line no-undef
                        const slider = carouselInlineWidget();
                        if (slider && typeof slider.stop === 'function') {
                            slider.stop();
                        }
                    };

                    script.id = 'testimonials';
                    document.head.appendChild(script);
                }
                clearInterval(scriptLoading);
            }
        }, TIMEOUT);
    }

    render() {
        return (
            <TestimonialsComponent
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialsContainer);
