export const PDP = 'pdp';
export const POPUP = 'popup';
export const CATEGORY = 'category';
export const CUSTOMER_ACCOUNT = 'customer_account';
export const CUSTOMER_SUB_ACCOUNT = 'customer_sub_account';
export const CUSTOMER_ACCOUNT_PAGE = 'customer_account_page';
export const CUSTOMER_WISHLIST = 'wishlist';
export const CUSTOMER_ORDER = 'customer_order';
export const HOME_PAGE = 'home';
export const MENU = 'menu';
export const MENU_SUBCATEGORY = 'menu_subcategory';
export const SEARCH = 'search';
export const FILTER = 'filter';
export const CART = 'cart';
export const CART_OVERLAY = 'cart_overlay';
export const CART_EDITING = 'cart_editing';
export const CHECKOUT = 'checkout';
export const CHECKOUT_SUCCESS = 'checkout_success';
export const CHECKOUT_ACCOUNT = 'checkout_account';
export const CMS_PAGE = 'cannabis-seeds';
export const MY_ACCOUNT = 'my-account';
export const NO_MATCH = 'no-match';
export const CONTACT_US = 'contact-us';
export const PRODUCT_COMPARE = 'product-compare';
export const HEADER_TOPBAR = 'header_topbar';
export const HEADER_MOBILE_TOPBAR = 'header_mobile_topbar';
export const HEADER_MOBILE_YELLOW_TOPBAR = 'header_mobile_yellow_topbar';
export const MY_DASHBOARD = 'my_dashboard';
