import {
    ONE_STAR_SHARE as SOURCE_ONE_STAR_SHARE,
    STARS_COUNT as SOURCE_STARS_COUNT,
    STARS_GRANULARITY_PERCENT as SOURCE_STARS_GRANULARITY_PERCENT
} from 'SourceComponent/ProductReviewRating/ProductReviewRating.config';

// TODO: implement STARS_GRANULARITY_PERCENT
export const STARS_GRANULARITY_PERCENT = SOURCE_STARS_GRANULARITY_PERCENT;

// TODO: implement ONE_STAR_SHARE
export const ONE_STAR_SHARE = SOURCE_ONE_STAR_SHARE;

// TODO: implement STARS_COUNT
export const STARS_COUNT = SOURCE_STARS_COUNT;
